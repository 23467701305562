import {getPathPrefix, getRecord, getRecords, postRecord, putRecord, removeRecord} from "@/services/api";

export function getClusters(filterQuery, options = {}) {
    filterQuery.datatable = true
    if (options.toggleProgressSpinner !== true) options.toggleProgressSpinner = false
    return getRecords('/prologistics/dashboard/' + getPathPrefix() + 'clusters.json', filterQuery, options)
}

export function getCluster(id, filterQuery = {}, options = {}) {
    return getRecord('/prologistics/dashboard/' + getPathPrefix() + 'clusters/' + id + '.json', filterQuery, options)
}

export function postCluster(data, options = {}) {
    return postRecord('/prologistics/dashboard/' + getPathPrefix() + 'clusters.json', data, options)
}

export function putCluster(id, data, options = {}) {
    return putRecord('/prologistics/dashboard/' + getPathPrefix() + 'clusters/' + id + '.json', data, options)
}

export function removeCluster(id, options = {}) {
    return removeRecord('/prologistics/dashboard/' + getPathPrefix() + 'clusters/', id, options)
}

export function getSelectableClusters(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/dashboard/' + getPathPrefix() + 'clusters.json', filterQuery, options)
}